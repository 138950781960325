import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import { XsParagraph } from '../elements/Paragraphs';
import { UnorderedList } from '../elements/Lists';

const CcpaAddendum = () => (
  <Layout>
    <SEO title="CCPA Addendum" />
    <Container>
      <Row marginBottom="144">
        <Column>
          <LgTitle>CCPA Addendum</LgTitle>
          <XsParagraph>Effective: January 1, 2020</XsParagraph>
          <XsParagraph>
            This CCPA addendum, privacy and cookie notice ("Notice"), explains
            your rights as a California consumer regarding personal information
            that is subject to the California Consumer Privacy Act ("CCPA").
            This Notice, which supplements the information contained in our
            Privacy Policy about Wise Publishing’s general privacy practices,
            applies only to California residents.
          </XsParagraph>
          <XsParagraph>
            This Notice only describes Wise Publishing’s privacy practices with
            regard to personal information that is subject to the CCPA ("CCPA
            Information"). The Privacy Policy, and not this Notice, describes
            Wise Publishing’s privacy practices relating to personal information
            collected, processed, sold, or disclosed, which is not subject to
            the CCPA. Only that information which is different or in addition to
            the Privacy Policy in reflected here. The Privacy Policy also
            includes other information about Wise Publishing’s privacy practices
            generally.
          </XsParagraph>
          <XsParagraph>
            Wise Publishing acknowledges the intention of the California
            legislature as follows:
          </XsParagraph>
          <XsParagraph>The right of Californians to:</XsParagraph>
          <UnorderedList>
            <li>
              know what personal information is being collected about them;
            </li>
            <li>
              know whether their personal information is sold or disclosed and
              to whom;
            </li>
            <li>say no to the sale of personal information;</li>
            <li>access their personal information; and</li>
            <li>
              equal service and price, even if they exercise their privacy
              rights.
            </li>
          </UnorderedList>
          <XsParagraph>
            The Wise Publishing Privacy Policy includes information on the
            categories of personal information it collects and the specifics of
            what has been collected (#3) the sources (#3), the business or
            commercial purpose (#4), the third parties with whom it is shared
            (#5).
          </XsParagraph>
          <XsParagraph>
            Under the CCPA, California residents have certain rights to request
            access to and deletion of CCPA Information. The Wise Publishing
            Privacy Policy provides how to request and how you can restrict
            collection of your information (#10).
          </XsParagraph>
          <XsParagraph>
            In response to a request, we will delete CCPA Information we have
            collected from you unless the CCPA Information is needed for the
            following:
          </XsParagraph>
          <UnorderedList>
            <li>
              To complete a transaction for which we collected the CCPA
              Information, provide a good or service you requested, take actions
              reasonably anticipated within the context of our ongoing business
              relationship with you, or otherwise perform our contract with you,
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities,
            </li>
            <li>
              Debug products to identify or repair errors that impair
              functionality,
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law,
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us,
            </li>
            <li>
              Comply with a legal obligation, including compliance with the
              California Electronic Communications Privacy Act, or
            </li>
            <li>
              Otherwise use CCPA Information about you, internally, in a lawful
              manner that is compatible with the context in which you provided
              the information.
            </li>
          </UnorderedList>
          <XsParagraph>
            To better protect you and CCPA Information about you, we will only
            respond to access or deletion requests that we have been able to
            properly verify through our authentication processes. To verify your
            identity, you will be asked to provide certain information, which we
            will only use to verify your identity and/or your authority to make
            the request. We will not respond to more than two access requests
            from you within a 12-month period.
          </XsParagraph>
          <XsParagraph>
            We will not discriminate against you for exercising any of your
            rights under the CCPA.
          </XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default CcpaAddendum;
