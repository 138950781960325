import styled from 'styled-components';
import tw from 'tailwind.macro';
import { math } from 'polished';

export const UnorderedList = styled.ul`
  ${tw`font-sans list-disc mb-32 pl-32 text-base text-purple-300`};
  line-height: ${math('22/17')};

  a {
    ${tw`font-serif italic underline`};
  }
`;
